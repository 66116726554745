import React from 'react';

import Layout from '../components/Layout';

const IndexPage = () => (
  <Layout fullMenu>
    <article id="main">
      <header>
        <h2>AGB</h2>
        <p>CMS DIREKTWERBUNG GMBH</p>
      </header>
      <section className="wrapper style5">
        <div className="inner">
          <h3>Allgemeine Geschäftsbedingungen der CMS-Direkwerbung GmbH</h3>
          <p>
           1.Angebot und Vertragsabschluss<br/><br/>
          </p>
          <p>
           Angebote erfolgen stets freibleibend und unter Ausschluss etwaiger entgegenstehender Allgemeiner Geschäftsbedingungen des Auftraggebers. 
           Der vom Auftraggeber unterzeichnete bzw. fernmündlich erteilte Verteilauftrag ist ein bindendes Angebot. Der Vertrag kommt zustande, wenn die 
           CMS Direktwerbung GmbH (im weiteren „Anbieter“ genannt) dieses Angebot durch Zusendung einer Auftragsbestätigung innerhalb von 4 Wochen annimmt. 
           Vertragliche Nebenabreden bzw. Modifizierungen der vertraglichen Pflichten des Auftraggebers und des Anbieters bedürfen, um Wirksamkeit zu 
           entfalten, der Schriftform.
          </p>

          <hr />

          <p>
              1.Geltung der Allgemeinen Geschäftsbedingungen<br/><br/>
          </p>
          <p>
              Diese Allgemeinen Geschäftsbedingungen gelten ausschließlich und spätestens mit dem Zustandekommen des Vertrages gemäß Ziff. I. 
              als vom Auftraggeber angenommen. Die Allgemeinen Geschäftsbedingungen gelten auch für Verteilaufträge, die dem Anbieter in Zukunft erteilt 
              werden, ohne Rücksicht darauf, ob der Anbieter in jedem Einzelfall ausdrücklich auf sie Bezug nimmt.
          </p>
          <p>
             III. Preise und Zahlungen<br/>
          </p>
          <p>
              Die angebotenen Preise sind verbindlich, wenn sich die seitens des Auftraggebers erklärten Daten, die zur Preiskalkulation notwendig sind, 
              als zutreffend erweisen. Stellen sich die entsprechenden Daten als unzutreffend heraus, ist die Vergütung für die Verteilung neu zu verhandeln. 
              Die dann vereinbarten Preise gelten auch für Teile desselben Verteilauftrages, den der Anbieter bereits erfüllt hat. Preisangaben in Angeboten 
              und Auftragsbestätigungen des Anbieters erfolgen ansonsten stets frei bleibend.
          </p>
          <p>
              Die Rechnungsstellung erfolgt nach Beendigung des Verteilauftrages. Bei Verteilaufträgen, die ein größeres Volumen haben, behält sich der 
              Anbieter vor, wöchentlich abzurechnen. Gegenstand dieser Berechnung wird das wöchentliche Verteilvolumen sein.
          </p>
          <p>
              Falls nicht anders vereinbart, sind Rechnungen rein netto 10 Tage nach Erhalt ohne jeden Abzug zur Zahlung fällig. Wechsel werden nicht 
              akzeptiert. Bei Zahlungsverzug des Auftraggebers kann der Anbieter die weitere Ausführung eines laufenden Verteilauftrages bis zur Zahlung 
              der restlichen Vergütung zurückstellen und für die Ausführung des Restverteilauftrages Vorauszahlung verlangen.
          </p>
          <p>
             Falls der Auftraggeber mit ihm obliegenden Zahlungsverpflichtungen in Verzug gerät, ist der Anbieter – unbeschadet aller 
             anderen Rechte – berechtigt, unter Setzung einer Nachfrist von 5 Werktagen vom Vertrag zurückzutreten.
          </p><br/><br/>
          <p>
              1.Lieferung des Verteilgutes, Ausführung des Verteilauftrages
          </p><br/><br/>
          <p>
             Der Auftraggeber verpflichtet sich, das Verteilgut rechtzeitig, jedoch spätestens 3 Tage vor dem vereinbarten Beginn des Verteilauftrages 
             dem Anbieter zu übergeben. Sollte es sich um einen umfangreichen Verteilauftrag handeln, verpflichtet sich der Auftraggeber zur Lieferung des 
             Verteilgutes in einem Umfang, dass dem Anbieter die Erfüllung der Verteilleistung grundsätzlich ermöglicht wird. Falls durch nicht rechtzeitige 
             Anlieferung des Verteilgutes oder durch kurzfristige Auftragsänderung der Beginn der Verteilung verzögert wird, verschieben
          </p>
          <p>
            sich vertraglich vereinbarte Verteiltermine entsprechend. Außerdem ist der Anbieter berechtigt, dem Auftraggeber die dadurch entstandenen 
            Aufwendungen (Löhne für Verteil- und Kontrollpersonal, Fahrtkosten, Spesen usw.) in Rechnung zu stellen.
          </p>
          <p>
            Das Verteilgut, welches der Anbieter von dem Auftraggeber übernommen hat, bewahrt der Anbieter auf Gefahr des Auftraggebers mit der Sorgfalt 
            eines ordentlichen Kaufmannes auf. Das Verteilgut verbleibt bis zur Verteilung im vertraglich vereinbarten Verteilgebiet im Eigentum des 
            Auftraggebers. Der Anbieter haftet nicht für die Richtigkeit der
          </p>
          <p>
            Stückzahlen, die sich innerhalb der Verpackungseinheiten befinden. Da die Aufbewahrung des Verteilgutes keine wesentliche Vertragspflicht 
            darstellt, haftet der Anbieter für den Verlust des Verteilgutes, wenn er diesen vorsätzlich oder grob fahrlässig zu vertreten hat. 
            Überdrucke des Vereilgutes, die bis zu 10 % der Gesamtauflage ausmachen, können
          </p>
          <p>
            ohne dass diese Position auf den Lieferscheinen ausgewiesen ist, nur nach gesondertem schriftlichem Auftrag des Auftraggebers verteilt werden.
          </p>
          <p>
            Wenn nicht anders vereinbart, erfolgt die Verteilung ausschließlich an private Briefkästen, ausgezeichnet durch ein Namensschild. Unabhängig 
            von der Anzahl der auf dem Briefkasten stehenden Namen, wird ein Exemplar pro Briefkasten eingeworfen. In Hochhäusern in denen ein 
            Briefkasteneinwurf ausdrücklich nicht gewünscht ist, wird eine
          </p>
          <p>
            entsprechende Menge an den dafür vorgesehenen Platz abgelegt. Befindet sich die Briefkastenanlage im Hausinneren hinter
          </p>
          <p>
            verschlossenen Türen bzw. Toren, so wird dort mehrfach geklingelt. Wird von den Bewohnern des Hauses nicht geöffnet, so können die innen 
            liegenden Briefkästen nicht beliefert werden. Briefkästen, welche mit Werbeverboten gekennzeichnet sind, dürfen grundsätzlich nicht beliefert 
            werden.
          </p>
          <p>
            Gewerbebetriebe, Geschäfte, Büros, Krankenhäuser, Heime, Kasernen, sowie Häuser auf Werks- und Betriebsgeländen und freistehende Häuser oder 
            Gehöfte außerhalb eines zusammenhängenden Wohngebietes sind von der Verteilung ausgenommen.
          </p><br/><br/>
          <p>
              1.Gewährleistung
          </p><br/><br/>
          <p>
             Der Anbieter strebt die Belieferung von ca. 90 % aller im Zielgebiet erreichbaren Haushalte an. Der Anbieter ist berechtigt, Subunternehmer 
             einzusetzen, haftet dann jedoch uneingeschränkt für deren Leistungen.
          </p>
          <p>
             Der Anbieter haftet nicht für den Werbeerfolg.
          </p>
          <p>
            Reklamationen und Rügen die Verteilleistung betreffend, müssen unverzüglich an den Anbieter gerichtet werden. Die Reklamation muss grundsätzlich 
            schriftlich erfolgen und den Tag, den Ort, die Straße, die Hausnummer und den Namen sowie die näheren Umstände des Mangels enthalten. 
            Damit Reklamationen überprüft und abgestellt werden können, müssen diese bis spätestens 7 Tage nach Verteiltermin beim Anbieter schriftlich 
            eingehen. Die Verteilung durch den Anbieter oder einen beauftragten Dritten gilt nach Ablauf von 7 Tagen als mangelfrei durch den Auftraggeber 
            genehmigt.
          </p>
          <p>
             Dem Anbieter wird die Möglichkeit eingeräumt, nach einer begründeten Beanstandung Nachbesserung zu gewähren. Mängelrügen zu einem Teilgebiet 
             der Verteilleistung, können sich nur auf die Vergütung für dieses Teilgebiet beziehen.
          </p>
          <p>
             Für begründete Beanstandungen, die auf eigenes Verschulden des Anbieters zurück zu führen sind, wird dem Auftraggeber angemessener 
             Schadensersatz im Verhältnis zur Fehlleistung erstattet. Der Auftraggeber erhält eine Gutschrift über die Stückzahl des von der begründeten 
             Beanstandung betroffenen Verteilgebietes.
          </p>
          <p>
            Sollte die angestrebte Abdeckungsquote unterschritten werden, so steht dem Auftraggeber grundsätzlich eine Minderung der geschuldeten 
            Vergütung in dieser prozentualen Größenordnung zu. Schadensersatz kann höchstens bis zur Höhe des Auftragswertes geleistet werden. 
            Weitergehende Regressansprüche sind ausgeschlossen.
          </p>
          <p>
            Sollte sich die Mangelrüge als unbegründet erweisen, da sich der Anbieter vertragsgerecht verhalten hat, können die Kosten zur Überprüfung 
            der Mangelrüge dem Auftraggeber belastet werden.
          </p><br/><br/>
          <p>
              1.Haftungsausschluss
          </p><br/><br/>
          <p>
            Hat der Anbieter auf Grund der gesetzlichen Bestimmungen nach Maßgabe dieser Bedingungen für einen Schaden aufzukommen, der leicht 
            fahrlässig verursacht wurde, so haftet der Anbieter beschränkt. Die Haftung besteht nur im Zusammenhang mit der Verletzung vertragswesentlicher 
            Pflichten. Diese Beschränkung gilt nicht bei Verletzung von Leben, Körper und
          </p>
          <p>
            Gesundheit. Ausgeschlossen ist die persönliche Haftung des gesetzlichen Vertreters des Anbieters, Erfüllungsgehilfen und Betriebsangehörigen 
            des Anbieters für von ihnen durch leichte Fahrlässigkeit verursachte Schäden.
          </p>
          <p>
            VII. Aufrechnung und Zurückbehaltungsrecht
          </p><br/><br/>
          <p>
            Der Auftraggeber ist berechtigt, mit unbestrittenen oder rechtskräftig festgestellten Gegenforderungen die Aufrechnung zu erklären. 
            Der Aufraggeber und der Anbieter sind sich im Zusammenhang mit dem Zurückbehaltungsrecht einig, dass der Auftraggeber hinsichtlich der 
            Lieferung des Verteilgutes vorleistungspflichtig ist und das demnach das Zurückbehaltungsrecht im gegenseitigen Vertragsverhältnis 
            ausgeschlossen
          </p>
          <p>
              ist.
          </p>
          <p>
          VIII. Pfandrecht
          </p><br/><br/>
          <p>
            Dem Anbieter steht wegen seiner Forderung aus dem Auftrag ein vertragliches Pfandrecht an den auf Grund des Auftrages in seinen 
            Besitz gelangten Gegenständen zu. Das vertragliche Pfandrecht kann auch wegen Forderungen aus früher durchgeführten Verteilaufträgen 
            geltend gemacht werden. Dies gilt nur, soweit diese Forderungen mit dem Auftragsgegenstand in Zusammenhang stehen. Für sonstige Ansprüche 
            aus der Geschäftsverbindung gilt das vertragliche Pfandrecht nur, soweit diese unbestritten sind oder ein rechtskräftiger Titel vorliegt und 
            das Verteilgut im
          </p>
          <p>
            Eigentum des Auftraggebers steht.
          </p>
          <p>
              1.Sonderbedingungen
          </p><br/><br/>
          <p>
            Höhere Gewalt oder bei dem Anbieter oder dem beauftragten Dritten eintretende Betriebsstörungen, die den Anbieter ohne eigenes Verschulden 
            vorübergehend daran hindern, die Verteilung zum vereinbarten Termin oder innerhalb der vereinbarten Frist durchzuführen, verändern den 
            vertraglich vereinbarten Termin zur Verteilung des Werbegutes. Der Anbieter haftet in diesem Falle nicht für die Termineinhaltung.
          </p><br/><br/>
          <p>
            Erfüllungsort und Gerichtsstand ist Offenbach am Main.
          </p>
        </div>
      </section>
    </article>
  </Layout>
);

export default IndexPage;
